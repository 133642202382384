import {
    Badge,
    Col,
    Container,
    Row,
    Spinner,
    Button,
    InputGroup,
    Form,
    Modal,
    Alert,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import TopBar from "../components/TopBar";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Data } from "../../Data";
import { toast } from "react-toastify";
import { getError } from "../../utils/utils";
import Axios from "axios";
import { Helmet } from "react-helmet-async";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { getCountryCodes } from "../utils/LocationUtils";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return { ...state, loading: true };
        case "FETCH_SUCCESS":
            return { ...state, infoData: action.payload, loading: false };
        case "FETCH_FAIL":
            return { ...state, loading: false };
        case "FETCH_REQUEST_AUTH":
            return { ...state, loadingAuth: true };
        case "FETCH_SUCCESS_AUTH":
            return { ...state, loadingAuth: false };
        case "FETCH_FAIL_AUTH":
            return { ...state, loadingAuth: false };
        case "FETCH_REQUEST_LOGIN":
            return { ...state, loadingLogin: true };
        case "FETCH_SUCCESS_LOGIN":
            return { ...state, loadingLogin: false };
        case "FETCH_FAIL_LOGIN":
            return { ...state, loadingLogin: false };
        default:
            return state;
    }
};

export default function Profile() {
    const [{ loading, loadingAuth, loadingLogin, infoData }, dispatch] =
        useReducer(reducer, {
            infoData: [],
            loading: true,
            loadingAuth: false,
            loadingLogin: false,
        });

    const { state } = useContext(Data);
    const { userInfo } = state;
    const [showAuth, setShowAuth] = useState(false);
    const [authCode, setAuthCode] = useState("");
    const [formType, setFormType] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newPhone, setNewPhone] = useState("");
    const [newPhoneCode, setNewPhoneCode] = useState("+420");

    const handleSaveForm = async (e, form_type) => {
        e.preventDefault();
        if (
            (form_type === "email" && newEmail.length === 0) ||
            (form_type === "phone" && newPhone.length === 0)
        ) {
            toast.error("Pole nesmí být prázdné!");
            return;
        }
        dispatch({ type: "FETCH_REQUEST_AUTH" });
        setFormType(form_type);
        try {
            let dataIn;
            if (form_type === "email") {
                dataIn = {
                    type: form_type,
                    email: newEmail,
                };
            } else if (form_type === "phone") {
                dataIn = {
                    type: form_type,
                    phone: newPhone,
                    phone_code: newPhoneCode,
                };
            }
            const { data } = await Axios.post("/api/profile/request", dataIn, {
                headers: {
                    authorization: `Bearer ${userInfo.token}`,
                },
            });
            dispatch({ type: "FETCH_SUCCESS_AUTH" });
            toast.info(data.message);
            setShowAuth(true);
        } catch (err) {
            dispatch({ type: "FETCH_FAIL_AUTH" });
            toast.error(getError(err));
            setAuthCode("");
        }
    };
    const handleCloseAuth = () => {
        setShowAuth(false);
    };

    const authLoginHandler = async (e) => {
        e.preventDefault();
        if (
            (formType === "email" && newEmail.length === 0) ||
            (formType === "phone" && newPhone.length === 0)
        ) {
            toast.error("Pole nesmí být prázdné!");
            return;
        }
        dispatch({ type: "FETCH_REQUEST_LOGIN" });
        try {
            let dataIn;
            if (formType === "email") {
                dataIn = {
                    type: formType,
                    code: authCode,
                    email: newEmail,
                };
            } else if (formType === "phone") {
                dataIn = {
                    type: formType,
                    code: authCode,
                    phone: newPhone,
                    phone_code: newPhoneCode,
                };
            }
            const { data } = await Axios.post("/api/profile/verify", dataIn, {
                headers: {
                    authorization: `Bearer ${userInfo.token}`,
                },
            });
            dispatch({ type: "FETCH_SUCCESS_LOGIN" });
            toast.success(data.message);
            toast.warning(
                "Údaje mohou být do systému zapsány až za několik minut."
            );
            setShowAuth(false);
            setNewEmail("");
            setNewPhone("");
            setNewPhoneCode("+420");
            setAuthCode("");
            const fetchData = async () => {
                dispatch({ type: "FETCH_REQUEST" });
                try {
                    const { data } = await Axios.post(
                        "/api/info/",
                        {
                            request: "info",
                        },
                        {
                            headers: {
                                authorization: `Bearer ${userInfo.token}`,
                            },
                        }
                    );
                    dispatch({ type: "FETCH_SUCCESS", payload: data });
                    //console.log(data);
                } catch (err) {
                    dispatch({ type: "FETCH_FAIL" });
                    toast.error(getError(err));
                }
            };
            fetchData();
        } catch (err) {
            dispatch({ type: "FETCH_FAIL_LOGIN" });
            toast.error(getError(err));
            setAuthCode("");
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: "FETCH_REQUEST" });
            try {
                const { data } = await Axios.post(
                    "/api/info/",
                    {
                        request: "info",
                    },
                    {
                        headers: {
                            authorization: `Bearer ${userInfo.token}`,
                        },
                    }
                );
                dispatch({ type: "FETCH_SUCCESS", payload: data });
                //console.log(data);
            } catch (err) {
                dispatch({ type: "FETCH_FAIL" });
                toast.error(getError(err));
            }
        };
        fetchData();
    }, [userInfo]);
    return (
        <>
            <Helmet>
                <title>Údaje | De Vries Group, a.s.</title>
            </Helmet>
            <TopBar />
            <Modal
                show={showAuth}
                onHide={handleCloseAuth}
                backdrop="static"
                keyboard={false}
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2 className="mb-0">OVĚŘENÍ</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="mb-4">Zadejte přístupový kód</h3>
                    <Form onSubmit={(e) => authLoginHandler(e)}>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Přístupový kód"
                                aria-label="Přístupový kód"
                                aria-describedby="basic-addon1"
                                value={authCode}
                                minLength={6}
                                maxLength={6}
                                onChange={(e) => setAuthCode(e.target.value)}
                            />
                        </InputGroup>
                        <Alert
                            variant="warning"
                            className="mt-4 d-flex gap-2 align-items-start small"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="xsmall-icon flex-shrink-0"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                                />
                            </svg>
                            Údaje mohou být do systému zapsány až za několik
                            minut od úspěšného uložení.
                        </Alert>
                        <div className="d-flex align-items-center gap-3">
                            <Button variant="primary" type="submit">
                                Odeslat
                            </Button>
                            {loadingLogin && (
                                <div>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">
                                            Loading...
                                        </span>
                                    </Spinner>
                                </div>
                            )}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {loadingAuth && (
                <div className="loading-box-global">
                    <Spinner
                        animation="border"
                        role="status"
                        className="spinner-border-custom"
                    >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <h1>Načítání, prosím vyčkejte...</h1>
                </div>
            )}

            <main>
                <section className="home">
                    <Container>
                        <Row className="gy-4">
                            <Col xs={12} md={12}>
                                <h2 className="mb-0">Kontaktní údaje</h2>
                                <hr className="mb-4" />
                                {loading ? (
                                    <div>
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <>
                                        <Accordion alwaysOpen>
                                            <Accordion.Item
                                                key={"name"}
                                                eventKey={"name"}
                                                className="accordion-disabled"
                                            >
                                                <Accordion.Header>
                                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                        <p className="m-0">
                                                            <strong>
                                                                Jméno a
                                                                příjmení:
                                                            </strong>{" "}
                                                            {infoData.name}
                                                        </p>
                                                        <span className="btn btn-danger d-inline-flex align-items-center gap-2">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                    1.5
                                                                }
                                                                stroke="currentColor"
                                                                className="small-icon"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M6 18 18 6M6 6l12 12"
                                                                />
                                                            </svg>
                                                            Nelze upravit
                                                        </span>
                                                    </div>
                                                </Accordion.Header>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                key={"main_address"}
                                                eventKey={"main_address"}
                                                className="accordion-disabled"
                                            >
                                                <Accordion.Header>
                                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                        <p className="m-0">
                                                            <strong>
                                                                Adresa hlavní:
                                                            </strong>{" "}
                                                            {
                                                                infoData.main_address
                                                            }
                                                        </p>
                                                        <span className="btn btn-danger d-inline-flex align-items-center gap-2">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                    1.5
                                                                }
                                                                stroke="currentColor"
                                                                className="small-icon"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M6 18 18 6M6 6l12 12"
                                                                />
                                                            </svg>
                                                            Nelze upravit
                                                        </span>
                                                    </div>
                                                </Accordion.Header>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                key={"shipping_address"}
                                                eventKey={"shipping_address"}
                                                className="accordion-disabled"
                                            >
                                                <Accordion.Header>
                                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                        <p className="m-0">
                                                            <strong>
                                                                Adresa
                                                                doručovací:
                                                            </strong>{" "}
                                                            {infoData.contact_address ===
                                                            "" ? (
                                                                <Badge bg="danger">
                                                                    Není
                                                                    vyplněno
                                                                </Badge>
                                                            ) : (
                                                                infoData.contact_address
                                                            )}
                                                        </p>
                                                        <span className="btn btn-danger d-inline-flex align-items-center gap-2">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                    1.5
                                                                }
                                                                stroke="currentColor"
                                                                className="small-icon"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M6 18 18 6M6 6l12 12"
                                                                />
                                                            </svg>
                                                            Nelze upravit
                                                        </span>
                                                    </div>
                                                </Accordion.Header>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                key={"email"}
                                                eventKey={"email"}
                                                className="accordion-edit"
                                            >
                                                <Accordion.Header>
                                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                        <p className="m-0">
                                                            <strong>
                                                                Email:
                                                            </strong>{" "}
                                                            {infoData.email}
                                                        </p>
                                                        <span className="btn btn-primary d-inline-flex align-items-center gap-2">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                    1.5
                                                                }
                                                                stroke="currentColor"
                                                                className="small-icon icon-edit"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                                                />
                                                            </svg>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                    1.5
                                                                }
                                                                stroke="currentColor"
                                                                className="small-icon icon-times"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M6 18 18 6M6 6l12 12"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <form
                                                        onSubmit={(e) =>
                                                            handleSaveForm(
                                                                e,
                                                                "email"
                                                            )
                                                        }
                                                    >
                                                        <div className="row row-cols-1 row-cols-md-2 gy-3 gx-3">
                                                            <div className="col">
                                                                <Form.Group
                                                                    className="mb-3"
                                                                    controlId="email"
                                                                >
                                                                    <Form.Label>
                                                                        E-mail:
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="email"
                                                                        name="email"
                                                                        placeholder="Zadejte nový e-mail"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setNewEmail(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        value={
                                                                            newEmail
                                                                        }
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                Uložit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                key={"phone"}
                                                eventKey={"phone"}
                                                className="accordion-edit"
                                            >
                                                <Accordion.Header>
                                                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                        <p className="m-0">
                                                            <strong>
                                                                Telefon:
                                                            </strong>{" "}
                                                            {infoData.phone}
                                                        </p>
                                                        <span className="btn btn-primary d-inline-flex align-items-center gap-2">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                    1.5
                                                                }
                                                                stroke="currentColor"
                                                                className="small-icon icon-edit"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                                                />
                                                            </svg>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                    1.5
                                                                }
                                                                stroke="currentColor"
                                                                className="small-icon icon-times"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M6 18 18 6M6 6l12 12"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <form
                                                        onSubmit={(e) =>
                                                            handleSaveForm(
                                                                e,
                                                                "phone"
                                                            )
                                                        }
                                                    >
                                                        <div className="row row-cols-1 row-cols-md-2 gy-3 gx-3">
                                                            <div className="col">
                                                                <Form.Group
                                                                    className="mb-3"
                                                                    controlId="phone"
                                                                >
                                                                    <Form.Label>
                                                                        Telefon:
                                                                    </Form.Label>
                                                                    <Row className="gx-2 gy-2">
                                                                        <Col
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                5
                                                                            }
                                                                            md={
                                                                                4
                                                                            }
                                                                        >
                                                                            <span className="small-title">
                                                                                Předvolba
                                                                            </span>
                                                                            <Form.Select
                                                                                value={
                                                                                    newPhoneCode
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    setNewPhoneCode(
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                required
                                                                            >
                                                                                {getCountryCodes.map(
                                                                                    (
                                                                                        country
                                                                                    ) => (
                                                                                        <option
                                                                                            key={
                                                                                                country.code
                                                                                            }
                                                                                            value={
                                                                                                country.dial_code
                                                                                            }
                                                                                            selected={
                                                                                                country.code ===
                                                                                                "CZ"
                                                                                            }
                                                                                        >
                                                                                            {country.emoji +
                                                                                                " " +
                                                                                                country.dial_code}
                                                                                        </option>
                                                                                    )
                                                                                )}
                                                                            </Form.Select>
                                                                        </Col>
                                                                        <Col>
                                                                            <span className="small-title">
                                                                                Telefonní
                                                                                číslo
                                                                                <OverlayTrigger
                                                                                    placement="bottom"
                                                                                    overlay={
                                                                                        <Tooltip id="tooltip">
                                                                                            Zadejte
                                                                                            telefonní
                                                                                            číslo
                                                                                            bez
                                                                                            předvolby,
                                                                                            bez
                                                                                            mezer,
                                                                                            bez
                                                                                            znaků.
                                                                                            Např.
                                                                                            123456789.
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span className="tooltip-trigger">
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            fill="none"
                                                                                            viewBox="0 0 24 24"
                                                                                            strokeWidth={
                                                                                                1.5
                                                                                            }
                                                                                            stroke="currentColor"
                                                                                            className="xsmall-icon"
                                                                                        >
                                                                                            <path
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"
                                                                                                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                                                                            />
                                                                                        </svg>
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                            </span>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder="Zadejte nové telefonní číslo"
                                                                                name="phone"
                                                                                required
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    setNewPhone(
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    newPhone
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                Uložit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                        <Alert
                                            className="mt-4 d-flex gap-2 align-items-start"
                                            variant="info"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="small-icon flex-shrink-0"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                                />
                                            </svg>
                                            <span>
                                                Pokud nesedí jméno, příjmení
                                                nebo některá z adres, prosím
                                                kontaktujte nás prostřednictvím
                                                formuláře na podstránce{" "}
                                                <Link to="/requests">
                                                    'Žádosti'
                                                </Link>
                                                .
                                            </span>
                                        </Alert>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        </>
    );
}
